<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">消息通知</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <div class="tab">
      <h3 :class="isShowList===true?'active':''" @click="isShowList=true">消息列表</h3>
      <h3 :class="isShowList===false?'active':''" @click="isShowList=false" v-if="isAdd">我的发布</h3>
    </div>
    <ul
      v-show="isShowList"
      class="inform"
      v-for="(item,index) in userInformList"
      :key="index"
      @click="toDetail(item,'')"
    >
      <li class="title">
        <span>【通知】</span>
        {{item.title}}
      </li>
      <li class="name">发布人：{{item.manageName}}</li>
      <li class="time">{{item.publishTime}}</li>
    </ul>

    <ul
      class="inform2"
      v-show="!isShowList"
      v-for="(item) in createUserInformList"
      :key="item.id"
      @click="toDetail(item,'manager')"
    >
      <li class="left">
        <p class="title">
          <span>【通知】</span>
          {{item.title}}
        </p>
        <p class="mytime">
          <span>{{item.updateTime}}</span>
          <span class="mode" v-if="item.status===1">已发布</span>
          <span class="mode2" v-if="item.status===0">草稿</span>
          <span class="mode3" v-if="item.status===2">发布中</span>
        </p>
      </li>
      <li class="publish">
        <div>
          <button @click.stop="publishFn(item.id)" v-if="item.status===0">发布</button>
          <button @click.stop="editPublishFn(item)" v-if="item.status===0">编辑</button>
          <button @click.stop="deletePublishFn(item.id)">删除</button>
        </div>
      </li>
    </ul>
    <span v-if="!isShowList && isAdd" class="addinform" @click="toAdd">+</span>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import cancel from "@/assets/cancel.png";
import {
  getInformListAPI,
  getCreateInformListAPI,
  publishInformAPI,
  deletePublishAPI,
} from "@/api/inform";
export default {
  data() {
    return {
      isShowList: true,
      userInformList: [],
      createUserInformList: [],
      user: {
        isClassManager: true,
        isDepartmentManager: false,
        isOrgManager: false,
        msgId: "",
      },
      msgId: "",
      isAdd: false,
      cancel,
    };
  },
  created() {
    this.getList();
    this.isAddFn();
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    async getList() {
      const res = await getInformListAPI();
      if (res.code === 0) {
        this.userInformList = res.data;
        this.userInformList.forEach((item) => {
          item.imgs = JSON.parse(item.imgs);
        });
      }
      const response = await getCreateInformListAPI();
      if (response.code === 0) {
        this.createUserInformList = response.data;
        this.createUserInformList.forEach((item) => {
          item.imgs = JSON.parse(item.imgs);
        });
      }
    },
    async publishFn(id) {
      this.user.msgId = id;
      const res = await publishInformAPI(id, { ...this.user });
      if (res.code === 0) {
        Toast({
          message: "发布成功",
        });
        this.getList();
      }
    },
    toAdd() {
      this.$router.push("/inform-add");
    },
    toDetail(item, e) {
      this.$router.push({
        path: "/inform-detail",
        query: { item: JSON.stringify(item), type: e },
      });
    },
    editPublishFn(item) {
      this.$router.push({
        path: "inform-add",
        query: { item: JSON.stringify(item) },
      });
    },
    async deletePublishFn(msgId) {
      Dialog.confirm({
        title: "删除",
        message: "确定要删除该通知吗?",
      })
        .then(async () => {
          const res = await deletePublishAPI(msgId);
          if (res.code === 0) {
            Toast({
              message: "删除成功",
            });
            this.getList();
          }
        })
        .catch(() => {
          return;
        });
    },
    isAddFn() {
      const user = JSON.parse(sessionStorage.getItem("personInfo"));
      this.user.isClassManager = user.isClassManager;
      this.user.isDepartmentManager = user.isManager;
      if (!user.isClassManager && !user.isManager) {
        this.isAdd = false;
      } else {
        this.isAdd = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;
    z-index: 99;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .tab {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    h3 {
      font-weight: 700;
      height: 35px;
      line-height: 35px;
    }
    .active {
      color: steelblue;
      border-bottom: 3px solid steelblue;
    }
  }
  .inform {
    width: 90%;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
    .title {
      height: 2.5rem;
      line-height: 2.5rem;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        font-weight: 700;
        margin-left: 0;
      }
    }

    .name,
    .time {
      font-size: 12px;
      height: 1.8rem;
      text-indent: 0.5rem;
      color: #aaaaaa;
    }
  }

  .addinform {
    color: white;
    font-size: 3rem;
    display: inline-block;
    width: 3rem;
    line-height: 3rem;
    height: 3rem;
    text-align: center;
    background: deepskyblue;
    border-radius: 1.5rem;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
  }
  .inform2 {
    width: 90%;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    .publish {
      width: 40%;
      div {
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        button {
          width: 29%;
          height: 1.5rem;
          background: deepskyblue;
          border: none;
          color: #ffffff;
          border-radius: 0.5rem;
          margin-right: 3%;
          margin-top: 10%;
        }
      }
    }
    .left {
      width: 60%;
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title {
        span {
          font-weight: 700;
        }
      }

      .mytime {
        font-size: 0.8rem;
        height: 2.5rem;
        line-height: 2.5rem;
        text-indent: 0.5rem;
        color: #aaaaaa;
        .mode {
          margin-left: 1rem;
          font-size: 16px;
          color: yellowgreen;
        }
        .mode2 {
          margin-left: 1rem;
          font-size: 16px;
          color: red;
        }
        .mode3 {
          margin-left: 1rem;
          font-size: 16px;
          color: #fe9f12;
        }
      }
    }
  }
}
</style>